<template>
  <form-frequency />
</template>

<script>
import formFrequency from '../form/medication/Form-medication-frequency.vue'

export default ({
  components: {
    formFrequency,
  },
})
</script>
